<template>
  <div>
    <div class="page-header custom-container">
      <img class="img-title" src="@/assets/header_edit.svg" alt />
      <span>修改好友資料</span>
    </div>
    <div class="member custom-container-xl">
      <div class="member__box">
        <div class="member__photo">
          <div class="photo__inset">
            <div class="photo__bg">
              <div class="photo"></div>
              <v-avatar size="142" class="photo">
                <img :src="Icon" />
              </v-avatar>
            </div>
          </div>
        </div>
        <div class="member__space"></div>
        <!-- 資料輸入 -->
        <div class="personal__info">
          <div class="personal__item">
            <span class="item__title">姓名</span>
            <div class="item__input">
              <input
                type="text"
                v-model="name"
                placeholder="請輸入姓名"
                maxlength="20"
              />
              <img
                class="input__error"
                src="@/assets/icon_wrong.svg"
                v-if="!nameCheck"
                alt
              />
            </div>
          </div>
          <div class="personal__item">
            <span class="item__title">性別</span>
            <div class="item__sex">
              <div
                class="sex-box male"
                :class="{ activeSex: activeSex == '男' }"
                @click="selectMale"
              >
                男生
              </div>
              <div
                class="sex-box female"
                :class="{ activeSex: activeSex == '女' }"
                @click="selectFemale"
              >
                女生
              </div>
            </div>
          </div>
          <div class="personal__item">
            <span class="item__title">出生日期</span>

            <div class="item__input" @click.stop="dateModal = true">
              <span>{{ date }}</span>
              <v-dialog
                ref="dialog"
                v-model="dateModal"
                :return-value.sync="date"
                width="290px"
                persistent
              >
                <v-date-picker
                  class="picker date-picker"
                  v-model="date"
                  :first-day-of-week="0"
                  locale="zh-cn"
                  scrollable
                  header-color="rgba(217, 217, 217, 0.25)"
                  color="primary"
                  max="2099-12-31"
                  min="0676-01-01"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="error" @click="dateModal = false"
                    >取消</v-btn
                  >
                  <v-btn text color="error" @click="$refs.dialog.save(date)"
                    >確認</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </div>
          </div>
          <div class="personal__item">
            <span class="item__title">出生時間</span>
            <!-- - -->
            <div class="time-wrap">
              <div class="item__input" @click.stop="timeModal = true">
                <span>{{ time }}</span>
                <v-dialog
                  ref="dialog2"
                  v-model="timeModal"
                  :return-value.sync="time"
                  width="290px"
                  persistent
                >
                  <v-time-picker
                    class="picker time-picker"
                    v-model="time"
                    v-if="timeModal"
                    scrollable
                    ampm-in-title
                    header-color="rgba(217, 217, 217, 0.25)"
                    color="primary"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="timeModal = false"
                      >取消</v-btn
                    >
                    <v-btn text color="error" @click="$refs.dialog2.save(time)"
                      >確認</v-btn
                    >
                  </v-time-picker>
                </v-dialog>
              </div>
              <label class="checkbox-wrap">
                我不知道
                <input type="checkbox" v-model="IDontKnow" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="personal__item">
            <span class="item__title"
              >日光節約時間
              <img src="@/assets/日光節約icon.svg" />
            </span>
            <div class="item__sex">
              <div
                class="sex-box male"
                :class="{ activeSex: IsDaylight }"
                @click="
                  IsDaylight = true;
                  DaylightNotice = true;
                "
              >
                是
                <div class="daylight" v-if="DaylightNotice">
                  系統將自動<br />
                  以日光節約時間測算
                </div>
              </div>
              <div
                class="sex-box female"
                :class="{ activeSex: !IsDaylight }"
                @click="
                  IsDaylight = false;
                  DaylightNotice = false;
                "
              >
                否
              </div>
            </div>
          </div>
          <div class="personal__item">
            <span class="item__title">出生地點</span>

            <div class="item__input">
              <v-autocomplete
                class="select"
                :items="locationItems"
                v-model="location"
                label="請選擇或輸入出生地點"
                background-color="transparent"
                solo
                hide-details
              ></v-autocomplete>
            </div>
          </div>

          <!-- 新增其他地點 -->
          <div class="personal__item" v-if="showOtherPlace">
            <span class="item__title otherPlace">出生國家</span>
            <div class="item__input">
              <v-autocomplete
                class="select"
                :items="$CountryCode"
                item-text="ChineseName"
                item-value="Code"
                v-model="CountryCode"
                label="請選擇或輸入出生國家"
                background-color="transparent"
                solo
                hide-details
              ></v-autocomplete>
            </div>
          </div>
          <div class="personal__item" v-if="showOtherPlace">
            <span class="item__title otherPlace">出生州省</span>
            <div class="item__input">
              <input
                type="text"
                v-model="State"
                value
                placeholder="請輸入州或省，例如 New York"
              />
            </div>
          </div>
          <div class="personal__item" v-if="showOtherPlace">
            <span class="item__title otherPlace">出生城市</span>
            <div class="item__input">
              <input
                type="text"
                v-model="City"
                placeholder="請輸入城市，例如 Buffalo"
              />
              <img
                class="input__error"
                v-if="!cityCheck"
                src="@/assets/icon_wrong.svg"
                alt
              />
              <div class="notice_inputEnglish" v-if="showOtherPlace">
                州和城市輸入英文可提高準確度。
              </div>
            </div>
          </div>
          <!-- 新增其他地點end -->
        </div>
        <!-- 資料輸入 End-->
        <div class="personal__btns">
          <div class="personal__cancel" @click="cancel">取消</div>
          <div
            class="personal__save"
            :class="{ notReady: fileNotApply }"
            @click="update"
          >
            儲存編輯
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: require("@/assets/cat.png"),
      DaylightNotice: false,
      logined: false,
      name: null,
      Icon: require("@/assets/cat.png"),
      activeSex: "Male",
      date: "1990-05-30",
      dateModal: false,
      time: "12:00",
      timeModal: false,
      State: null,
      City: null,
      CountryCode: "TW",
      showOtherPlace: false,
      passLocation: "Taipei",
      location: "台北",
      locationItems: [
        "基隆",
        "台北",
        // "台北(Google)",
        "新北",
        "桃園",
        "新竹",
        "苗栗",
        "台中",
        "彰化",
        "南投",
        "雲林",
        "嘉義",
        "台南",
        "高雄",
        "屏東",
        "台東",
        "花蓮",
        "宜蘭",
        "澎湖",
        "金門",
        "馬祖",
        "香港",
        "澳門",
        "其他",
      ],
      checklist: [
        { text: "基隆", value: "Keelung" },
        { text: "台北", value: "Taipei" },
        // { text: "台北(Google)", value: "Taipei4Google" },
        { text: "新北", value: "New Taipei" },
        { text: "桃園", value: "Taoyuan" },
        { text: "新竹", value: "Hsinchu" },
        { text: "苗栗", value: "Miaoli" },
        { text: "台中", value: "Taichung" },
        { text: "彰化", value: "Changhua" },
        { text: "南投", value: "Nantou" },
        { text: "雲林", value: "Yunlin" },
        { text: "嘉義", value: "Chiayi" },
        { text: "台南", value: "Tainan" },
        { text: "高雄", value: "Kaohsiung" },
        { text: "屏東", value: "Pingtung" },
        { text: "台東", value: "Taitung" },
        { text: "花蓮", value: "Hualien" },
        { text: "宜蘭", value: "Yilan" },
        { text: "澎湖", value: "Penghu" },
        { text: "金門", value: "Kinmen" },
        { text: "馬祖", value: "Mazu" },
        { text: "香港", value: "Hongkong" },
        { text: "澳門", value: "Macao" },
        { text: "其他", value: "Other" },
      ],
      Gender: "M",
      userBeEdit: this.$store.state.userBeEdit,
      IDontKnow: false,
      nameCheck: true,
      countryCheck: true,
      cityCheck: true,
      fileNotApply: false,
      canClick: true,
      IsDaylight: false,
      sourceCountry: null,
      //星盤ID
      AstrolabeFileId:
        this.$store.state.userBeEdit.AstrolabeFileId ||
        window.localStorage.getItem("editUser"),
    };
  },
  computed: {
    Country() {
      let Country = this.$CountryCode.find((item) => {
        return item.Code == this.CountryCode;
      });
      return Country ? Country.ChineseName : "台灣";
    },
    Zone() {
      let Country = this.$CountryCode.find((item) => {
        return item.Code == this.CountryCode;
      });
      return Country ? parseFloat(Country.Zone) : 8.0;
    },
  },
  watch: {
    location: function (value) {
      this.checklist.forEach((item) => {
        if (item.text === value) {
          this.passLocation = item.value;
        }
      });
      if (value === "其他") {
        this.showOtherPlace = true;
        this.fileNotApply = true;
      } else {
        this.showOtherPlace = false;
        if (this.nameCheck) {
          this.fileNotApply = false;
        }
        this.countryCheck = true;
        this.cityCheck = true;
        this.City = null;
      }
    },
    Country: function (value) {
      if (this.location === "其他") {
        if (value === null || value === "") {
          this.countryCheck = false;
          this.fileNotApply = true;
        } else {
          this.countryCheck = true;
          if (this.nameCheck && this.cityCheck) {
            this.fileNotApply = false;
          }
        }
      }
    },
    City: function (value) {
      if (this.location === "其他") {
        if (value === null || value === "") {
          this.cityCheck = false;
          this.fileNotApply = true;
        } else {
          this.cityCheck = true;
          if (this.nameCheck && this.countryCheck) {
            this.fileNotApply = false;
          }
        }
      }
    },
    name: function (value) {
      if (value === null || value === "") {
        this.nameCheck = false;
        this.fileNotApply = true;
      } else {
        this.nameCheck = true;
        if (this.countryCheck && this.cityCheck) {
          this.fileNotApply = false;
        }
      }
    },
    IDontKnow: function (value) {
      if (value === true) {
        this.time = "12:00";
      }
    },
  },
  created() {
    this.getFriendDate();
  },
  methods: {
    selectMale() {
      this.activeSex = "男";
      this.Gender = "M";
    },
    selectFemale() {
      this.activeSex = "女";
      this.Gender = "F";
    },
    cancel() {
      window.localStorage.removeItem("editUser");
      this.$router.go(-1);
    },
    getFriendDate() {
      //直接進來此路由，沒攜帶ID就前往好友列表
      if (!this.AstrolabeFileId) {
        this.$router.push("/");
        return;
      }
      this.$API.Get_AstrolabeFile(this.AstrolabeFileId).then((data) => {
        this.userBeEdit = data.Data;
        this.location = this.userBeEdit.BirthPlace;
        if (this.location === "其他") {
          this.showOtherPlace = true;
        }
        this.State = this.userBeEdit.BirthState;
        this.City = this.userBeEdit.BirthCity;
        this.CountryCode = this.userBeEdit.BirthLang || "TW";
        this.sourceCountry = this.userBeEdit.BirthCountry;
        this.name = this.userBeEdit.Name;
        this.IsDaylight = this.userBeEdit.IsDaylight;
        this.activeSex = this.userBeEdit.Gender;
        this.Icon = this.userBeEdit.ProfilePhotoSrc;
        if (this.activeSex === "Female") {
          this.Gender = "F";
        }
        this.time = this.userBeEdit.BirthTime;
        let BD = this.userBeEdit.BirthDay;
        let arr = BD.split("/");
        BD = arr.join("-");
        this.date = BD;
        if (this.sourceCountry) {
          this.findCountryCode();
        }
      });
    },
    update() {
      this.$noticePopup(() => {
        if (this.location === "其他") {
          if (this.City === null || this.City === "") {
            this.cityCheck = false;
          } else {
            this.cityCheck = true;
          }
          if (this.Country === null || this.Country === "") {
            this.countryCheck = false;
          } else {
            this.countryCheck = true;
          }
        }

        if (this.nameCheck && this.cityCheck && this.countryCheck) {
          if (this.canClick) {
            this.fileNotApply = true;
            setTimeout(() => {
              this.fileNotApply = false;
            }, 3000);
            this.canClick = false;
            let relationship =
              this.userBeEdit.Relationship == "自己"
                ? "R0"
                : this.userBeEdit.Relationship;

            if (this.IDontKnow) {
              this.time = "12:00";
            }
            let BD = this.date.split("-");
            BD[0] = this.$formatNumber(BD[0]);
            BD = BD.join("/");
            const BirthTime = BD + " " + this.time;
            let data = {
              AstrolabeFileId: this.userBeEdit.AstrolabeFileId,
              Relationship: relationship,
              Name: this.name,
              Gender: this.Gender,
              BirthTime: BirthTime,
              BirthPlace: this.passLocation,
              BirthCountry: this.Country,
              BirthState: this.State ? this.State : null,
              BirthCity: this.City,
              BirthCountryCode: this.CountryCode,
              BirthTimeZone: this.Zone,
              IsDaylight: this.IsDaylight,
            };
            this.$API
              .Update_AstrolabeFile(data)
              .then((data) => {
                if (!data) {
                  this.canClick = true;
                  alert("輸入的國家或地區找不到，請重新輸入");
                  return;
                }

                window.localStorage.setItem("friendState", "Edit");
                this.$router.push("friendList");
              })
              .catch((err) => {
                this.canClick = true;
                alert("輸入的國家或地區找不到，請重新輸入喔");
              });
          }
        } else {
          alert("請輸入必填資料");
          this.fileNotApply = true;
        }
      });
    },
    findCountryCode() {
      let CountryCode = this.$CountryCode.find((item) => {
        return item.ChineseName == this.sourceCountry;
      });
      this.CountryCode = CountryCode ? CountryCode.Code : "";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/header.scss";
@import "@/scss/userFile.scss";
@import "@/scss/editFile.scss";

footer {
  margin-top: 0;
  @include pad {
    margin-top: 44px;
  }
}
</style>
